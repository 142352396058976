@import "~bootstrap/dist/css/bootstrap.min.css";

:root {
  --green: #00a197;
  --yellow: #f2e205;
}

@font-face {
  font-family: Comfortaa-VariableFont_wght;
  src: url(../fonts/Comfortaa/Comfortaa-VariableFont_wght.ttf);
}

body {
  font-family: Comfortaa-VariableFont_wght;
  margin: 0px;
}

.slider {
  margin-top: 10px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  height: 500px;
}

.header-nav-item,
.container-green {
  background-color: var(--green);
}

.header-nav-link,
.header-nav-link:hover,
.header-nav-link:focus,
.header-nav-link:active {
  font-weight: bold;
  color: #ffffff;
}

article > section > * {
  white-space: pre-line;
}
